import path from "path";

const i18nConfig = {
  i18n: {
    locales: ["en", "ko"],
    defaultLocale: "ko",
  },
  localePath: path.resolve("./public/locales"),
  reloadOnPrerender: process.env.NODE_ENV === "development", // 개발 환경에서 핫 리로드 활성화
};

export default i18nConfig; // ✅ 전체 설정을 default export
