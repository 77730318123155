import type { FC, ReactElement, ReactNode } from "react";
import { useEffect } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { SessionProvider } from "next-auth/react";
// i18n settings
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import i18n from "i18next";
import enCommon from "public/locales/en/common.json";
import common from "public/locales/ko/common.json";
import type { EmotionCache } from "@emotion/cache";
import { DehydratedState } from "@tanstack/react-query";

import { createEmotionCache } from "@shared/lib//utils/create-emotion-cache";
import { AnalyticsTrackerByRouter } from "@/components/common/features/analytics-tracker";
import { ChannelTalkController } from "@/components/common/features/channel-talk";
// import { RefreshAccessTokenErrorObserver } from '@/components/common/features/refresh-access-token-error-observer'
import { seoConfig } from "@/config/next-seo";
import ReactQueryProvider from "@/providers/react-query-provider";
import { StyleProvider } from "@/providers/style-provider";
import { Session } from "@/types/auth";
import Script from "next/script";

const FACEBOOK_PIXEL_ID = "2640176276142590"; // Meta Pixel ID
const GTM_ID = "GTM-5CBJSHBC"; // Google Tag Manager ID
const GA_IDS = ["AW-16622388763", "AW-10825671224", "G-W2Y62Q64CY"]; // Google Analytics IDs

i18n.use(initReactI18next).init({
  resources: {
    ko: {
      common,
    },
    en: {
      enCommon,
    },
  },
  lng: "ko",
  fallbackLng: false,
  interpolation: {
    escapeValue: false,
  },
});

import { useRouter } from "next/router";
import nextI18nextConfig from "next-i18next.config.mjs";
import dayjs from "dayjs";

import "moment/locale/ko";
import "dayjs/locale/ko";
import "dayjs/locale/en";

export type NextPageWithLayout<P = object> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type EnhancedAppProps = AppProps<{
  session?: Session | null;
  dehydratedState?: DehydratedState;
}> & {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = (props) => {
  const {
    Component,
    pageProps: {
      /**
       * getServerSideProps에서
       * next-auth의 getServerSession을 이용해서 session을 꺼낸 다음
       * props.session에 session을 넘김
       * 그렇게 하면 _app에서 pageProps.session을 가져올 수 있음
       * pageProps에서 session을 제외한 나머지를 페이지 컴포넌트로 전달하고
       * session은 SessionProvider로 전달하여
       * 1. session은 useSession을 통해서만 사용할 수 있도록 하고
       * 2. useSession을 통해서 가져오는 session의 최초 값을 설정함
       *    (클라이언트 사이드에서 세션을 가져오는 로딩을 없앨 수 있음)
       */
      session,
      dehydratedState,
      ...pageProps
    },
    emotionCache = clientSideEmotionCache,
  } = props;

  const getLayout = Component?.getLayout ?? ((page) => page);
  const { i18n } = useTranslation();
  const { locale } = useRouter();

  useEffect(() => {
    if (locale && i18n.language !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale, i18n]);

  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  /**
   * 오른쪽 클릭 방지
   */
  useEffect(() => {
    const preventContext = (e: MouseEvent) => e.preventDefault();
    document.addEventListener("contextmenu", preventContext);

    return () => document.removeEventListener("contextmenu", preventContext);
  }, []);

  const seo = seoConfig[locale as "ko" | "en"] || seoConfig.ko;

  return (
    <>
      <DefaultSeo {...seo} />

      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, viewport-fit=cover"
        />
        <link
          rel="icon"
          href="/favicon.ico"
          sizes="any"
          type="image/x-icon"
        />
      </Head>

      {/* Facebook Meta Pixel */}
      <Script
        id="facebook-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s) {
              if (f.fbq) return; n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n; n.loaded = !0; n.version = '2.0';
              n.queue = []; t = b.createElement(e); t.async = !0;
              t.src = 'https://connect.facebook.net/en_US/fbevents.js';
              s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s);
            }(window, document, 'script');

            fbq('init', '${FACEBOOK_PIXEL_ID}');
            fbq('track', 'PageView');
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
          alt="Facebook Pixel"
        />
      </noscript>

      {/* Google Tag Manager */}
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
          `,
        }}
      />

      {/* Google Analytics (GA) */}
      {GA_IDS.map((id) => (
        <Script
          key={id}
          id={`ga-script-${id}`}
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
        />
      ))}

      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            ${GA_IDS.map((id) => `gtag('config', '${id}');`).join("\n")}
          `,
        }}
      />

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="GTM"
        />
      </noscript>

      <SessionProvider
        session={session}
        // 30분
        refetchInterval={60 * 30}
        refetchOnWindowFocus={true}
      >
        <ReactQueryProvider dehydratedState={dehydratedState}>
          <StyleProvider emotionCache={emotionCache}>
            <AnalyticsTrackerByRouter />
            <ChannelTalkController />
            {getLayout(<Component {...pageProps} />)}
          </StyleProvider>
        </ReactQueryProvider>
      </SessionProvider>
    </>
  );
};

export default appWithTranslation(App, nextI18nextConfig);
