import { DefaultSeoProps } from "next-seo";

export const THUMBNAIL_URL = `/images/default-thumbnail.png`;
export const TITLE = "케아클 | K-ArtistClass Global Learning Platform";

export const seoConfig = {
  ko: {
    openGraph: {
      type: "website",
      locale: "ko_KR",
      url: "https://k-artistclass.com",
      siteName: "케아클 | K-ArtistClass Global Learning Platform",
      title: "케아클 | K-ArtistClass Global Learning Platform",
      description:
        "어디서든 만날 수 있는 나만의 아티스트클래스. 당신을 위한 아티스트 클래스를 만나보세요. K-아티스트클래스.",
      images: [
        {
          url: THUMBNAIL_URL,
          width: 1200,
          height: 630,
          alt: TITLE,
        },
      ],
    },

    title: "케아클 | K-ArtistClass Global Learning Platform",
    description:
      "어디서든 만날 수 있는 나만의 아티스트클래스. 당신을 위한 아티스트 클래스를 만나보세요. K-아티스트클래스.",
    canonical: "https://k-artistclass.com",
    additionalMetaTags: [
      {
        name: "subject",
        content: "케아클 | K-ArtistClass Global Learning Platform",
      },
      {
        name: "keywords",
        content:
          "k-artist, kartist, k아티스트, k-artistclass, k-아티스트클래스, k아티스트클래스, 케이아티스트클래스, k-masterclass, k마스터클래스, 케마클, 케아클, 케이에이씨미디어그룹, kacmediagroup, 비대면레슨, 라이브클래스, 원데이클래스, 성우준비, 성우지망생, 성우독학, 성우레슨, 단문연기, 단문, 나레이션피드백, 나레이션독학, 성우연기, 김영선, 최덕희, 작곡독학, 미디작곡, 작곡레슨, 자작곡, 작곡",
      },
      {
        name: "google-site-verification",
        content: "OJ85WR8m4msvxBiIugHFu_Epu63PaY3OASCn7IeyljU",
      },
      {
        name: "naver-site-verification",
        content: "d32f3a7378fbd2d715bd17321a05bd820f5d0c3e",
      },
      {
        name: "facebook-domain-verification",
        content: "1cdtxg5aiothv00nrlb8b3fywugjow",
      },
    ],

    twitter: {
      site: "https://k-artistclass.com",
      cardType: "summary",
      title: "케아클 | K-ArtistClass Global Learning Platform",
      description:
        "어디서든 만날 수 있는 나만의 아티스트클래스. 당신을 위한 아티스트 클래스를 만나보세요. K-아티스트클래스.",
      image: THUMBNAIL_URL,
    },
  } as DefaultSeoProps,

  en: {
    openGraph: {
      type: "website",
      locale: "en_US",
      url: "https://k-artistclass.com/en",
      siteName: "K-ARTIST CLASS | K-POP Global Learning Platform",
      title: "K-ARTIST CLASS | K-POP Global Learning Platform",
      description:
        "Your own K-POP Artist Class, accessible anywhere. Discover an K-ARTIST CLASS designed just for you.",
      images: [
        {
          url: THUMBNAIL_URL,
          width: 1200,
          height: 630,
          alt: TITLE,
        },
      ],
    },

    title: "K-ARTIST CLASS | K-POP Global Learning Platform",
    description:
      "Your own K-POP Artist Class, accessible anywhere. Discover an K-ARTIST CLASS designed just for you.",
    canonical: "https://k-artistclass.com/en",
    additionalMetaTags: [
      {
        name: "subject",
        content: "K-ARTIST CLASS | K-POP Global Learning Platform",
      },
      {
        name: "keywords",
        content:
          "K-ARTIST CLASS, K-ARTISTCLASS, K-MASTER CLASS, K-MASTERCLASS, KAC MEDIA GROUP, KACMEDIAGROUP, K-POP, K-POP Lesson, K-POP Online Lesson, K-POP Live Class, Learning Composition, MIDI Composition, Songwriting Lesson, Composition, Songwriting",
      },
      {
        name: "google-site-verification",
        content: "OJ85WR8m4msvxBiIugHFu_Epu63PaY3OASCn7IeyljU",
      },
      {
        name: "naver-site-verification",
        content: "d32f3a7378fbd2d715bd17321a05bd820f5d0c3e",
      },
      {
        name: "facebook-domain-verification",
        content: "1cdtxg5aiothv00nrlb8b3fywugjow",
      },
    ],
    twitter: {
      site: "https://k-artistclass.com",
      cardType: "summary",
      title: "K-ARTIST CLASS | K-POP Global Learning Platform",
      description:
        "Your own K-POP Artist Class, accessible anywhere. Discover an K-ARTIST CLASS designed just for you.",
      image: THUMBNAIL_URL,
    },
  } as DefaultSeoProps,
};
