import { FC, ReactNode, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import localFont from "next/font/local";
import Router from "next/router";
import nProgress from "nprogress";
import smoothScroll from "smoothscroll-polyfill";
import { EmotionCache } from "@emotion/cache";
import { CacheProvider, Global } from "@emotion/react";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#2065EC",
      light: "#757ce8",
      dark: "#002884",
      contrastText: "#fff",
    },
    success: { main: "#2E7D32" },
    error: { main: "#D32F2F" },
    text: { disabled: "#E0E0E0", secondary: "#00000099" },
  },
});

import GlobalStyles from "@shared/lib//styles/global-styles";
import {
  Theme as KacThemeType,
  theme as kacTheme,
} from "@shared/lib//styles/theme";

import "nprogress/nprogress.css";

declare module "@emotion/react" {
  export interface Theme extends KacThemeType {}
}

if (typeof window !== "undefined") {
  smoothScroll.polyfill();
}

nProgress.configure({ showSpinner: false });

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

const pretendard = localFont({
  src: "../assets/fonts/PretendardVariable.woff2",
  display: "swap",
  weight: "45 920",
});

const Fonts: FC = () => (
  <style
    jsx
    global
  >
    {`
      :root {
        --pretendard: ${pretendard.style.fontFamily};
      }

      body {
        font-family: ${pretendard.style.fontFamily} !important;
      }
    `}
  </style>
);

const VhRootVariableSetter: FC = () => {
  const [vh, setVh] = useState<number>();

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight / 100);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!vh) {
    return null;
  }

  return (
    <style
      jsx
      global
    >
      {`
        :root {
          --vh: ${vh}px;
        }
      `}
    </style>
  );
};

interface StyleProps {
  emotionCache: EmotionCache;
  children: ReactNode;
}

export const StyleProvider = ({ children, emotionCache }: StyleProps) => (
  <>
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <CacheProvider value={emotionCache}>
        <ThemeProvider
          theme={{
            ...kacTheme,
          }}
        >
          {children}
          <Global styles={GlobalStyles} />
          <Fonts />
          <VhRootVariableSetter />
          <Toaster />
        </ThemeProvider>
      </CacheProvider>
    </MuiThemeProvider>
  </>
);
